/**
 * Stylesheet with the CSS for Font Awesome
 */

$fa-font-path: '@fortawesome/fontawesome-free/webfonts';
$fa-font-display: block;

@use '@fortawesome/fontawesome-free/scss/fontawesome' with (
  $fa-font-path: $fa-font-path,
  $fa-font-display: $fa-font-display
);

@use '@fortawesome/fontawesome-free/scss/regular' with (
  $fa-font-path: $fa-font-path,
  $fa-font-display: $fa-font-display
);

@use '@fortawesome/fontawesome-free/scss/solid' with (
  $fa-font-path: $fa-font-path,
  $fa-font-display: $fa-font-display
);

@use '@fortawesome/fontawesome-free/scss/brands' with (
  $fa-font-path: $fa-font-path,
  $fa-font-display: $fa-font-display
);
